<template>
    <div class="maxapp">
        <div class="block site">
            <span class="demonstration">请选择要修改的站点：</span>
            <el-cascader placeholder="试试搜索：广东" :options="options" filterable @change="select"></el-cascader>
        </div>
        <div class="maxyd">
            <!-- 左侧功能区 -->
            <div class="yd_left">
                <div class="zixun" @click="dhhide">
                    <p>咨询图标</p>
                    <div class="tubiao">
                        <div class="tubiao_top">
                            <span>图标类型</span>
                            <div :class="num == 1 ? 'active' : ''" @click="num = 1">
                                <div class="ico_tu" :class="num == 1 ? 'border' : ''">
                                    <div class="chang" :class="num == 1 ? 'background' : ''"></div>
                                </div>
                                <div class="middle_zhong">底部</div>
                            </div>
                            <div :class="num == 2 ? 'active' : ''" @click="num = 2">
                                <div class="ico_tu" :class="num == 2 ? 'border' : ''">
                                    <div class="chang1" :class="num == 2 ? 'background' : ''"></div>   
                                </div>
                                <div class="middle_zhong">圆形</div>
                            </div>
                            <div :class="num == 3 ? 'active' : ''" @click="num = 3">
                                <div class="ico_tu" :class="num == 3 ? 'border' : ''">
                                    <div class="chang2" :class="num == 3 ? 'background' : ''"></div>
                                </div>
                                <div class="middle_zhong">横向</div>
                            </div>
                            <div :class="num == 4 ? 'active' : ''" @click="num = 4">
                                <div class="ico_tu" :class="num == 4 ? 'border' : ''">
                                    <div class="chang" :class="num == 4 ? 'background' : ''">
                                        <div></div>
                                    </div>
                                </div>
                                <div class="middle_zhong">输入框</div>
                            </div>
                            <div :class="num == 5 ? 'active' : ''" @click="num = 5">
                                <div class="ico_tu" :class="num == 5 ? 'border' : ''">
                                    <div class="chang" :class="num == 5 ? 'background' : ''">
                                        <div></div>
                                    </div>
                                </div>
                                <div class="middle_zhong">百度
                                    <el-tooltip class="item" effect="dark" content="适用于百度落地页规则要求" placement="bottom">
                                    <i class="el-icon-info"></i>
                                    </el-tooltip>
                                </div>
                            </div>
                            <div :class="num == 6 ? 'active' : ''" @click="num = 6">
                                <div class="ico_tu" :class="num == 6 ? 'border' : ''">
                                    
                                </div>
                                <div class="middle_zhong">无标题</div>
                            </div>
                        </div>
                        <div>
                            <div v-show="num == 1">
                                <!-- <div class="flex lf1"><span>文案</span><span class="ziyanse">高级自定义</span></div> -->
                                <div class="flex lf"><span>颜色</span><span class="flex2"><el-color-picker v-model="color1"
                                            @active-change="yxzhuti"></el-color-picker>主题色</span></div>
                                <div class="flex lf1"><span>文案</span><el-input class="wenan" v-model="input"
                                        placeholder="请输入内容"></el-input></div>
                                <div class="flex flex1">
                                    <span>消息提示</span>
                                    <div class="tishi">
                                        <el-button plain :class="block == 1 ? 'active1' : ''"
                                            @click="block = 1">卡片</el-button>
                                        <el-button plain :class="block == 2 ? 'active1' : ''"
                                            @click="block = 2">隐藏</el-button>
                                        <div v-show="block == 1" class="flex">
                                            <el-color-picker v-model="color2" @active-change="lapian"></el-color-picker>卡片背景
                                            <el-color-picker v-model="color3" @active-change="wenzi"></el-color-picker>文字颜色
                                        </div>
                                        <div v-show="block == 2"></div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="num == 2">
                                <div class="flex lf"><span>颜色</span><span class="flex2"><el-color-picker v-model="color1"
                                            @active-change="yxzhuti"></el-color-picker>主题色</span></div>
                                <div class="flex lf2"><span>图标位置</span>
                                    <span>距上<el-input-number v-model="num1" controls-position="right" :min="1" :max="100"
                                            class="weizhi"></el-input-number>%,
                                        距左<el-input-number v-model="num2" controls-position="right" :min="1" :max="100"
                                            class="weizhi"></el-input-number>%
                                    </span>
                                </div>
                                <div class="flex flex1">
                                    <span>消息提示</span>
                                    <div class="tishi">
                                        <el-button plain :class="block == 1 ? 'active1' : ''"
                                            @click="block = 1">卡片</el-button>
                                        <el-button plain :class="block == 2 ? 'active1' : ''"
                                            @click="block = 2">隐藏</el-button>
                                        <div v-show="block == 1" class="flex">
                                            <el-color-picker v-model="color2" @active-change="lapian"></el-color-picker>卡片背景
                                            <el-color-picker v-model="color3" @active-change="wenzi"></el-color-picker>文字颜色
                                        </div>
                                        <div v-show="block == 2"></div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="num == 3">
                                <div class="flex lf"><span>颜色</span><span class="flex2"><el-color-picker v-model="color1"
                                            @active-change="yxzhuti"></el-color-picker>主题色</span></div>
                                <div class="flex lf1"><span>文案</span><el-input class="wenan" v-model="input"
                                        placeholder="请输入内容"></el-input></div>
                                <div class="flex lf2"><span>图标位置</span>
                                    <span>距上<el-input-number v-model="num1" controls-position="right" :min="1" :max="100"
                                            class="weizhi"></el-input-number>%,
                                        距左<el-input-number v-model="num2" controls-position="right" :min="1" :max="100"
                                            class="weizhi"></el-input-number>%
                                    </span>
                                </div>
                                <div class="flex flex1">
                                    <span>消息提示</span>
                                    <div class="tishi">
                                        <el-button plain :class="block == 1 ? 'active1' : ''"
                                            @click="block = 1">卡片</el-button>
                                        <el-button plain :class="block == 2 ? 'active1' : ''"
                                            @click="block = 2">隐藏</el-button>
                                        <div v-show="block == 1" class="flex">
                                            <el-color-picker v-model="color2" @active-change="lapian"></el-color-picker>卡片背景
                                            <el-color-picker v-model="color3" @active-change="wenzi"></el-color-picker>文字颜色
                                        </div>
                                        <div v-show="block == 2"></div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="num == 4"></div>
                            <div v-show="num == 5"></div>
                            <div v-show="num == 6"></div>
                        </div>
                    </div>
                </div>
                <!-- 建立对话 -->
                <div class="duihua" @click="tab1()">
                    <p>建立对话</p>
                    <div class="duihua_boot">
                        <div class="flex">
                            <span>建立对话</span>
                            <div class="radio">
                                <el-radio v-model="radio" label="1" @input="block1 = 1">访客进入即自动建立</el-radio>
                                <el-radio v-model="radio" label="2" @input="block1 = 2">访客点击「咨询图标」建立</el-radio>
                            </div>
                        </div>
                        <div v-show="block1 == 1 && num != 5">
                            <div class="flex">
                                <span>首次弹开</span>
                                <div>
                                    <el-input-number v-model="nummiao" controls-position="right" :min="1" :max="100"
                                        class="weizhi"></el-input-number>秒杀，自动弹开聊天窗口
                                </div>
                            </div>
                            <div class="flex">
                                <span>反复弹开</span>
                                <div>
                                    <el-checkbox v-model="checked">每次间隔为</el-checkbox>
                                    <el-input-number v-model="jiange" controls-position="right" :min="1" :max="100"
                                        class="weizhi"></el-input-number>
                                    秒，最多 <el-input-number v-model="max" controls-position="right" :min="1" :max="100"
                                        class="weizhi"></el-input-number>次
                                </div>
                            </div>
                            <div class="flex">
                                <span>强制展开</span>
                                <div><el-checkbox v-model="checked1">首次弹开后，访客接收到新消息，强制展开浮动窗口</el-checkbox>
                                </div>
                            </div>
                        </div>
                        <div v-show="block1 == 2" class="flex">
                            <span>强制展开</span>
                            <div><el-checkbox v-model="checked1">首次弹开后，访客接收到新消息，强制展开浮动窗口</el-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 聊天窗口外观 -->
                <div class="waiguang" @click="tab1()">
                    <div class="liaotian">
                        <p>聊天窗口外观</p>
                        <span>设计符合品牌调性的聊天窗口</span>
                    </div>
                    <div class="duihua_boot">
                        <div class="flex">
                            <span>窗口占比</span>
                            <el-radio-group v-model="radio1" @change="heightdel">
                                <el-radio :label="3">60%</el-radio>
                                <el-radio :label="6">80%</el-radio>
                                <el-radio :label="9">90%</el-radio>
                                <el-radio :label="12" v-show="num != 4 && num != 5">新窗口打开</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="flex">
                            <span>窗口颜色</span>
                            <div class="ckys">
                                <div class="xuanzhe"><el-color-picker v-model="dhlys"
                                        @active-change="lt1"></el-color-picker><span>导航栏</span></div>
                                <div class="xuanzhe"><el-color-picker v-model="bttbys"
                                        @active-change="lt2"></el-color-picker><span>标题和图标</span></div>
                                <div class="xuanzhe"><el-color-picker v-model="kfqpys"
                                        @active-change="lt3"></el-color-picker><span>客服气泡</span></div>
                                <div class="xuanzhe"><el-color-picker v-model="kqxxys"
                                        @active-change="lt4"></el-color-picker><span>客服消息</span></div>
                                <div class="xuanzhe"><el-color-picker v-model="fkqpys"
                                        @active-change="lt5"></el-color-picker><span>访客气泡</span></div>
                                <div class="xuanzhe"><el-color-picker v-model="fkxxys"
                                        @active-change="lt6"></el-color-picker><span>访客消息</span></div>
                            </div>
                        </div>
                        <!-- <div class="flex">
                            <span>客服名片</span>
                            <el-checkbox v-model="mingp">显示</el-checkbox>
                            <div class="xiaoguo">如何设置客服名片</div>
                        </div> -->
                    </div>
                </div>
                <!-- 互动菜单 -->
                <div class="hudong" @click="tab1()">
                    <div class="caidang">
                        <dl>
                            <dt>客户互动菜单</dt>
                            <dd>提升访客对话率，提高访客转化</dd>
                            <dd><i class="el-icon-view"></i>预览案例</dd>
                        </dl>
                    </div>
                    <div class="duihua_boot">
                        <!-- <div class="flex">
                            <span>自动消息</span>
                            <el-checkbox v-model="xiaox">访客说话后，互动菜单消息</el-checkbox>
                        </div> -->
                        <div class="flex5">
                            <span>访客可点击菜单，进行快速提问</span>
                            <div class="xinjian" @click="hudong">+新建</div>
                        </div>
                        <el-table :data="tableData" style="width: 100%">
                            <el-table-column label="菜单名称">
                                <template slot-scope="scope">
                                    <i class="el-icon-s-grid"></i>
                                    <span style="margin-left: 10px">{{ scope.row.date }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="菜单类型">
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                    <el-button size="mini" type="danger"
                                        @click.native.prevent="handleDelete(scope.$index, tableData)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 新建互动菜单弹窗 -->
                        <el-dialog class="ziduan" :title="tctitle" :visible.sync="dialogVisible" width="380px">
                            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
                                class="demo-ruleForm">
                                <el-form-item label="菜单类型" prop="resource">
                                    <el-radio-group v-model="ruleForm.resource">
                                        <el-radio :label="1">快问快答</el-radio>
                                        <el-radio :label="2">快速提问</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <div v-if="ruleForm.resource == 1">
                                    <el-form-item label="菜单名称" prop="name">
                                        <el-input v-model="ruleForm.name"></el-input>
                                    </el-form-item>
                                    <el-form-item label="回复内容" prop="desc">
                                        <el-input type="textarea" v-model="ruleForm.desc"
                                            :autosize="{ minRows: 8, maxRows: 8 }"></el-input>
                                    </el-form-item>
                                </div>
                                <div v-else>
                                    <el-form-item label="菜单名称" prop="name" class="construction">
                                        <el-input v-model="ruleForm.name" label-width="20px"></el-input>
                                        <p class="caidan" @click="creation">+新建菜单</p>
                                    </el-form-item>

                                    <div class="demo-dynamic" v-if="erji">
                                        <el-form-item prop="email" label="二级菜单">
                                            <el-input v-model="ruleForm.email"></el-input>
                                        </el-form-item>
                                        <el-form-item v-for="(domain, index) in ruleForm.domains" label="二级菜单"
                                            :key="domain.key" :prop="'domains.' + index + '.value'" :rules="[{ required: true, message: '请输入菜单名称', trigger: 'blur' },
                                            { min: 0, max: 12, message: '不超过 12 个字符', trigger: 'blur' }]">
                                            <el-input v-model="domain.value"></el-input><el-button
                                                @click.prevent="removeDomain(domain)">删除</el-button>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button @click="addDomain">新增菜单</el-button>
                                        </el-form-item>
                                    </div>
                                </div>


                            </el-form>
                            <span slot="footer" class="dialog-footer">
                                <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                                <el-button @click="resetForm('ruleForm')">重置</el-button>
                            </span>
                        </el-dialog>
                    </div>
                </div>
                <!-- 岗位互动菜单 -->
                <div class="hudong" @click="tab1()">
                    <div class="caidang">
                        <dl>
                            <dt>岗位互动菜单</dt>
                            <dd>提升访客对话率，提高访客转化</dd>
                            <dd><i class="el-icon-view"></i>预览案例</dd>
                        </dl>
                    </div>
                    <div class="duihua_boot">
                        <!-- <div class="flex">
                            <span>自动消息</span>
                            <el-checkbox v-model="xiaox">访客说话后，互动菜单消息</el-checkbox>
                        </div> -->
                        <div class="flex5">
                            <span>访客可点击菜单，进行快速提问</span>
                            <div class="xinjian" @click="hud1ong">+新建</div>
                        </div>
                        <el-table :data="table1Data" style="width: 100%">
                            <el-table-column label="菜单名称">
                                <template slot-scope="scope">
                                    <i class="el-icon-s-grid"></i>
                                    <span style="margin-left: 10px">{{ scope.row.date }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="菜单类型">
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" @click="handle1Edit(scope.$index, scope.row)">编辑</el-button>
                                    <el-button size="mini" type="danger"
                                        @click.native.prevent="handle1Delete(scope.$index, table1Data)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 新建互动菜单弹窗 -->
                        <el-dialog class="ziduan" :title="tctitle" :visible.sync="dialog1Visible" width="380px">
                            <el-form :model="rule1Form" :rules="rules" ref="rule1Form" label-width="100px"
                                class="demo-ruleForm">
                                <el-form-item label="菜单类型" prop="resource">
                                    <el-radio-group v-model="rule1Form.resource">
                                        <!-- <el-radio :label="1">快问快答</el-radio> -->
                                        <el-radio :label="2">快速提问</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <div v-if="rule1Form.resource == 1">
                                    <el-form-item label="菜单名称" prop="name">
                                        <el-input v-model="rule1Form.name"></el-input>
                                    </el-form-item>
                                    <el-form-item label="回复内容" prop="desc">
                                        <el-input type="textarea" v-model="rule1Form.desc"
                                            :autosize="{ minRows: 8, maxRows: 8 }"></el-input>
                                    </el-form-item>
                                </div>
                                <div v-else>
                                    <el-form-item label="菜单名称" prop="name" class="construction">
                                        <el-input v-model="rule1Form.name" label-width="20px"></el-input>
                                        <p class="caidan" @click="crea1tion">+新建菜单</p>
                                    </el-form-item>

                                    <div class="demo-dynamic" v-if="erji">
                                        <el-form-item prop="email" label="详细内容">
                                            <el-input v-model="rule1Form.email"></el-input>
                                        </el-form-item>
                                        <el-form-item v-for="(domain, index) in rule1Form.domains" label="二级菜单"
                                            :key="domain.key" :prop="'domains.' + index + '.value'" :rules="[{ required: true, message: '请输入菜单名称', trigger: 'blur' },
                                            { min: 0, max: 30, message: '不超过 30 个字符', trigger: 'blur' }]">
                                            <el-input v-model="domain.value"></el-input><el-button
                                                @click.prevent="remove1Domain(domain)">删除</el-button>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button @click="add1Domain">新增菜单</el-button>
                                        </el-form-item>
                                    </div>
                                </div>


                            </el-form>
                            <span slot="footer" class="dialog-footer">
                                <el-button type="primary" @click="submit1Form('rule1Form')">立即创建</el-button>
                                <el-button @click="reset1Form('rule1Form')">重置</el-button>
                            </span>
                        </el-dialog>
                    </div>
                </div>
            </div>
            <!-- 右侧效果图 -->
            <div class="yd_right">
                <div>
                    <div class="glay-one"></div>
                    <div class="glay-two"></div>
                    <div class="glay-three"></div>
                    <div class="glay-four"></div>
                    <div class="glay-five"></div>
                    <div class="glay-six"></div>
                </div>

                <div>
                    <!-- 消息提示是否隐藏 -->
                    <div v-show="(num == 1 || num == 2 || num == 3) && block == 1" class="preview-guide-card-atbottom-box"
                        :style="{ background: color2 }">
                        <div class="preview-guide-card-atbottom-text" :style="{ color: color3 }">
                            现在有优惠活动奥，【表情】快点我！快点我！【图片】名额有限奥！！！立即领…</div><i
                            class="preview-guide-card-atbottom-close-icon"></i>
                    </div>
                    <!-- 底部 -->
                    <div class="preview-menu-box" v-show="num == 1">
                        <div class="preview-menu-bottom-tb" :style="{ backgroundColor: color1 }">
                            <span class="preview-menu-bottom-content-tb">
                                <span class="preview-menu-bottom-img-tb">
                                    <span class="preview-menu-bottom-info-num-tb">3</span>
                                    <img src="https://www14.53kf.com/style/setting/ver07/img/mobile_config/icon2.png"
                                        alt="">
                                </span>
                                <span class="preview-menu-bottom-title-tb">{{ input }}</span>
                            </span>
                        </div>
                    </div>
                    <!-- 圆形 -->
                    <div v-show="num == 2" class="preview-box-content-radius-qipaobox"
                        :style="{ top: num1 + '%', left: num2 + '%' }">
                        <div class="preview-box-radius-qipao" :style="{ background: color1 }">
                            <img src="https://www14.53kf.com/new_settings/static/media/radius_qipao.bd3022c5.svg" alt="">
                            <div class="preview-box-radius-qipao-redtext">1</div>
                        </div>
                    </div>
                    <!-- 横向 -->
                    <div v-show="num == 3" class="preview-box-lateral-picture-box"
                        :style="{ top: num1 + '%', left: num2 + '%', backgroundColor: color1 }">
                        <div class="preview-box-lateral-picture"><img
                                src="https://www14.53kf.com/new_settings/static/media/radius_qipao.bd3022c5.svg" alt="">
                            <div class="preview-box-lateral-number">1</div>
                        </div>
                        <div class="preview-box-lateral-text">{{ input }}</div>
                    </div>
                    <!-- 输入框 -->
                    <div v-show="num == 4" class="preview-box-content-inputbox">
                        <div class="preview-box-content-inputbox-body"><img src="图片" alt="">
                            <div class="preview-box-content-inputbox-bodymsg">嗨！欢迎咨询，点击与我沟通</div>
                            <div class="preview-box-content-inputbox-msgtips">新消息1</div>
                        </div>
                        <div class="preview-box-content-inputbox-foot"><span class="foot-smile"><i
                                    class="icon font_family icon-a-Emotion1x el-icon-chat-round"></i></span><span
                                class="foot-add"><i
                                    class="icon font_family icon-a-More1x el-icon-circle-plus-outline"></i></span><span
                                class="foot-text">请输入</span><span class="foot-text-next">发送</span></div>
                    </div>
                    <!-- 百度 -->
                    <div v-show="num == 5" class="preview-box-content-inputbox">
                        <div class="preview-box-content-inputbox-body"><img src="图片" alt="">
                            <div class="preview-box-content-inputbox-bodymsg">嗨！欢迎咨询，点击与我沟通</div>
                            <div class="preview-box-content-inputbox-msgtips">新消息1</div>
                        </div>
                    </div>

                </div>

                <!-- 建立对话框 -->
                <div v-show="duihua">
                    <div class="preview-box-autoopen-content"></div>
                    <div class="preview-box-customer-talk-box">
                        <div class="preview-box-customer-talk-head" :style="{ backgroundColor: dhlys, color: bttbys }"><span
                                class="head-text" style="">客服咨询</span><span class="head-close"><i
                                    class="icon font_family icon-close el-icon-close"></i></span><span class="head-phone"
                                v-show="phone"><i class="icon font_family icon-hujiaozhongxin el-icon-phone"></i></span>
                        </div>
                        <div class="preview-box-customer-talk-body" :style="{ height: gaodu + 'px' }">
                            <div class="preview-box-customer-talk-qipao"
                                :style="{ backgroundColor: fkqpys, color: fkxxys }">访客消息</div>
                            <div class="preview-box-servicer-talk-qipao">
                                <img src="图片" alt=""><span :style="{ backgroundColor: kfqpys, color: kqxxys }">客服消息</span>
                            </div>
                            <div class="mySwiper-box" style="transition: all 0.4s ease 0s;">
                                <div
                                    class="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events mySwiper">
                                    <div class="swiper-wrapper"
                                        style="transition-duration: 0ms; transform: translate3d(0px, 0px, 0px);">
                                        <div class="swiper-slide swiper-slide-active" style="width: 318px;">
                                            <!-- <div class="hudong-menu-box">
                                                <div class="menu-box" v-for="(val, index) in tableData" :key="index">{{
                                                    val.date }}</div>
                                            </div> -->
                                            <div class="swiper mySwiper usermenuswiper">
                                                <div class="swiper-wrapper usermenuson">
                                                    <div v-for="(item, index) in tableData" :key="index"
                                                        class="swiper-slide usermenuitem">{{ item.date }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="close-box"><span class="menu_btn font_family icon-close">X</span></div>
                            </div>
                        </div>
                        <div class="preview-box-customer-talk-foot"><span class="foot-smile"><i
                                    class="icon font_family icon-a-Emotion1x el-icon-chat-round"></i></span><span
                                class="foot-add"><i
                                    class="icon font_family icon-a-More1x el-icon-circle-plus-outline"></i></span><span
                                class="foot-text">请输入</span><span class="foot-text-next">发送</span></div>
                    </div>
                </div>



                <div class="footer">
                    <ul>
                        <li><i class="el-icon-arrow-left"></i></li>
                        <li><i class="el-icon-arrow-right"></i></li>
                        <li><i class="el-icon-postcard"></i></li>
                        <li><i class="el-icon-refresh-right"></i></li>
                        <li><i class="el-icon-folder"></i></li>
                    </ul>
                </div>



            </div>
        </div>
        <!-- 底部保存更新 -->
        <div class="baocun">
            <el-row>
                <el-button type="primary" @click="update">保存更新</el-button>
                <el-button plain @click="restore">还原更新</el-button>
            </el-row>
        </div>
    </div>
</template>

<script>
import { getsite, addStyle, getStyle } from "@/api/jiekou"
export default {
    name: 'XianmuFkYD',

    data() {
        return {
            activeName: 'second',
            num: 1,
            color1: '#409EFF', //圆形颜色
            gxcolor: '#409EFF', //横向颜色
            color2: '#fff', //卡片背景
            color3: '#000', //文字颜色
            input: '在线咨询',
            num1: 40,   //图标位置
            num2: 90,   //图标位置
            block: 1,
            duihua: true,   //对话框显示隐藏
            gaodu: 215.8,   //对话框的高度
            dhlys: "#2592FA",   //导航栏颜色
            bttbys: "#FFFFFF",  //标题和图标颜色
            kfqpys: "#EFF3F6",  //客服气泡
            kqxxys: "#3A3C4C",   //客服消息
            fkqpys: "#0094FF",    //访客气泡
            fkxxys: "#FFFFFF",    //访客消息
            phone: false, //电话咨询

            radio: '1',
            block1: 1,
            nummiao: 5,  //首次弹开秒数
            max: 100,  //最多次
            jiange: 3,  //每次间隔时间
            checked: false,  //反复弹出
            checked1: false,  //强制展开
            radio1: 3,  //窗口比例
            mingp: false, //客服名片
            xiaox: false,   //自动消息
            // 客服互动菜单
            tableData: [{
                date: '您好',
                name: '快问快答',
                desc: "您好",
                resource: 1
            }, {
                date: '请问呢',
                name: '快问快答',
                desc: "您好",
                resource: 1
            }],
            // 岗位互动菜单
            table1Data: [
            // {
            //     date: '您好',
            //     name: '快问快答',
            //     desc: "您好",
            //     resource: 1
            // }, {
            //     date: '请问',
            //     name: '快问快答',
            //     desc: "有什么能帮到你的地方",
            //     resource: 1
            // }
            ],
            dialogVisible: false,
            dialog1Visible: false,
            cjnum1: 40,
            cjnum2: 100,
            // 下拉框选择站点列表
            options: [],
            optionsId: "",
            tctitle: "设置显示字段",   //客户弹窗标题

            // 新建客户互动菜单弹窗
            ruleForm: {
                name: '',
                resource: 1,
                desc: '',
                domains: [
                    {
                    value: ''
                }],
                email: ''
            },
            // 新建岗位互动菜单弹窗
            rule1Form: {
                name: '',
                resource: 2,
                desc: '',
                domains: [{
                    value: ''
                }],
                email: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入菜单名称', trigger: 'blur' },
                    { min: 0, max: 30, message: '不超过 30 个字符', trigger: 'blur' }
                ],
                resource: [
                    { required: true, message: '菜单类型', trigger: 'change' }
                ],
                desc: [
                    { required: true, message: '请填写回复内容', trigger: 'blur' }
                ],
                email: [
                    { required: true, message: '请输入菜单名称', trigger: 'blur' },
                    { min: 0, max: 30, message: '不超过 30 个字符', trigger: 'blur' }
                ]
            },
            erji: false,
            redactId: "",   //保存点击编辑的id
            
        };
    },
    components: {

    },
    mounted() {
        this.GetSite()
        
    },

    methods: {
        // 点击新建客户互动菜单出现弹窗
        hudong() {
            this.dialogVisible = true
            this.tctitle = "设置显示字段"
            this.erji=false
            this.ruleForm = {
                name: '',
                resource: 1,
                desc: '',
                domains: [{
                    value: ''
                }],
                email: ''
            }
        },
        // 点击新建客户互动菜单出现弹窗
        hud1ong() {
            this.dialog1Visible = true
            this.tctitle = "设置显示字段"
            this.erji=false
            this.rule1Form = {
                name: '',
                resource: 2,
                desc: '',
                domains: [{
                    value: ''
                }],
                email: ''
            }
        },
        // 创建客户互动菜单
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.dialogVisible = false
                    // console.log(this.ruleForm)
                    if (this.tctitle == "设置显示字段") {
                        let res = {}
                        if (this.ruleForm.resource == 1) {
                            res.date = this.ruleForm.name
                            res.name = "快问快答"
                            res.resource = this.ruleForm.resource
                            res.desc = this.ruleForm.desc
                        } else {
                            res.date = this.ruleForm.name
                            res.name = "快速提问"
                            res.resource = this.ruleForm.resource
                            res.email = this.ruleForm.email
                            res.domains = this.ruleForm.domains
                        }
                        this.tableData.push(res)
                    } else {
                        let res = {}
                        if (this.ruleForm.resource == 1) {
                            res.date = this.ruleForm.name
                            res.name = "快问快答"
                            res.resource = this.ruleForm.resource
                            res.desc = this.ruleForm.desc
                        } else {
                            res.date = this.ruleForm.name
                            res.name = "快速提问"
                            res.resource = this.ruleForm.resource
                            res.email = this.ruleForm.email
                            res.domains = this.ruleForm.domains
                        }
                        this.tableData.splice(this.redactId, 1, res)
                    }
                } else {
                    // console.log('error submit!!');
                    return false;
                    
                }
            });
        },
        // 创建岗位互动菜单
        submit1Form(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.dialog1Visible = false
                    // console.log(this.rule1Form)
                    if (this.tctitle == "设置显示字段") {
                        let res = {}
                        if (this.rule1Form.resource == 1) {
                            res.date = this.rule1Form.name
                            res.name = "快问快答"
                            res.resource = this.rule1Form.resource
                            res.desc = this.rule1Form.desc
                        } else {
                            res.date = this.rule1Form.name
                            res.name = "快速提问"
                            res.resource = this.rule1Form.resource
                            res.email = this.rule1Form.email
                            res.domains = this.rule1Form.domains
                        }
                        this.table1Data.push(res)
                    } else {
                        let res = {}
                        if (this.rule1Form.resource == 1) {
                            res.date = this.rule1Form.name
                            res.name = "快问快答"
                            res.resource = this.rule1Form.resource
                            res.desc = this.rule1Form.desc
                        } else {
                            res.date = this.rule1Form.name
                            res.name = "快速提问"
                            res.resource = this.rule1Form.resource
                            res.email = this.rule1Form.email
                            res.domains = this.rule1Form.domains
                        }
                        this.table1Data.splice(this.redactId, 1, res)
                    }
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        // 重置重置
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        reset1Form(formName) {
            this.$refs[formName].resetFields();
        },

        // 删除客户二级菜单
        removeDomain(item) {
            var index = this.ruleForm.domains.indexOf(item)
            if (index !== -1) {
                this.ruleForm.domains.splice(index, 1)
            }
        },
        // 删除岗位二级菜单
        remove1Domain(item) {
            var index = this.rule1Form.domains.indexOf(item)
            if (index !== -1) {
                this.rule1Form.domains.splice(index, 1)
            }
        },
        //   新增客户二级菜单
        addDomain() {
            this.ruleForm.domains.push({
                value: '',
                key: Date.now()
            });
        },
        //   新增岗位二级菜单
        add1Domain() {
            this.rule1Form.domains.push({
                value: '',
                key: Date.now()
            });
        },
        // 新建客户二级菜单
        creation() {
            if (this.erji == false) {
                this.erji = true
            } else {
                this.erji = false
            }
        },
        // 新建岗位二级菜单
        crea1tion() {
            if (this.erji == false) {
                this.erji = true
            } else {
                this.erji = false
            }
        },
        // 选择站点
        async select(value) {
            // console.log(value[0])
            this.optionsId = value[0]
            let res = await getStyle({
                site_id: value[0],
                type: 1
            })
            // // console.log(res.data.data)
            if (res.data.data.length) {
                    // console.log(JSON.parse(res.data.data[0].setting))
                    let par = JSON.parse(res.data.data[0].setting)
                    this.num = par.icon.type
                    this.color1 = par.icon.colour
                    this.input = par.icon.text
                    this.num1 = par.icon.location.top
                    this.num2 = par.icon.location.left
                    this.block = par.icon.hintHide
                    this.color2 = par.icon.hintbgcolor
                    this.color3 = par.icon.hintcolor

                    this.block1 = par.dialogue.establish
                    this.nummiao = par.dialogue.first
                    this.checked = par.dialogue.repeatedly
                    this.jiange = par.dialogue.repeatedlyTime.interval
                    this.max = par.dialogue.repeatedlyTime.frequency
                    this.checked1 = par.dialogue.coerce

                    if (par.appearance.ratio == 215.8) {
                        this.radio1=3
                    } else if (par.appearance.ratio == 317.4) {
                        this.radio1=6
                    } else if (par.appearance.ratio == 368.2) {
                        this.radio1=9
                    } else {
                        this.radio1=12
                    }
                    this.gaodu=par.appearance.ratio
                    this.dhlys = par.appearance.color.navigation
                    this.bttbys = par.appearance.color.title
                    this.kfqpys = par.appearance.color.service
                    this.kqxxys = par.appearance.color.news
                    this.fkqpys = par.appearance.color.caller
                    this.fkxxys = par.appearance.color.callerxx
                    this.mingp = par.appearance.card

                    this.xiaox = par.menu.automatic
                    this.tableData = par.menu.Text

                    this.table1Data=par.station.TexT
                }

        },
        // 获取站点信息接口
        async GetSite() {
            let res = await getsite()
            // console.log(res.data.data)
            this.options = []
            res.data.data.forEach(val => {
                let arr = {}
                arr.value = val.id
                arr.label = val.site_name
                this.options.push(arr)
            })
        },

        //卡片颜色
        lapian(value) {
            this.color2 = value
        },
        // 文字颜色
        wenzi(value) {
            this.color3 = value
        },
        // 对话框显示隐藏
        tab1() {
            this.duihua = true
        },
        //点击让对话框隐藏
        dhhide() {
            this.duihua = false
        },

        //圆形颜色
        yxzhuti(value) {
            this.color1 = value
        },

        // 改变窗口占比
        heightdel(value) {
            if (value == 3) {
                this.gaodu = 215.8
            } else if (value == 6) {
                this.gaodu = 317.4
            } else if (value == 9) {
                this.gaodu = 368.2
            } else {
                this.gaodu = 419
            }
        },
        // 聊天窗口外观颜色
        lt1(value) {
            this.dhlys = value
        },
        lt2(value) {
            this.bttbys = value
        },
        lt3(value) {
            this.kfqpys = value
        },
        lt4(value) {
            this.kqxxys = value
        },
        lt5(value) {
            this.fkqpys = value
        },
        lt6(value) {
            this.kxxys = value
        },
        // 客户互动菜单编辑按钮
        handleEdit(index, val) {
            // console.log(val, index)
            this.dialogVisible = true
            this.tctitle = "修改菜单"
            this.redactId = index
            this.ruleForm.resource = val.resource
            if (val.resource == 2) {
                this.ruleForm.name = val.date
                this.ruleForm.domains = val.domains
                this.ruleForm.email = val.email
                if (val.email) {
                    this.erji = true
                }
                
            } else {
                this.ruleForm.name = val.date
                this.ruleForm.desc = val.desc
            }
        },
        // 客户互动菜单删除按钮
        handle1Delete(index, val) {
            val.splice(index, 1);
        },
        // 岗位互动菜单编辑按钮
        handle1Edit(index, val) {
            // console.log(val, index)
            this.dialog1Visible = true
            this.tctitle = "修改菜单"
            this.redactId = index
            this.rule1Form.resource = val.resource
            if (val.resource == 2) {
                this.rule1Form.name = val.date
                this.rule1Form.domains = val.domains
                this.rule1Form.email = val.email
                if (val.email) {
                    this.erji = true
                }
            } else {
                this.rule1Form.name = val.date
                this.rule1Form.desc = val.desc
            }
        },
        // 岗位互动菜单删除按钮
        handleDelete(index, val) {
            val.splice(index, 1);
        },
        // 保存更新
        async update() {
            if (this.optionsId) {
                let res = {
                    icon: {
                        type: this.num,  //图标类型
                        colour: this.color1,
                        text: this.input,
                        location: { top: this.num1, left: this.num2 },
                        hintHide: this.block,   //判断是否有消息提示  1有 2没有
                        hintbgcolor: this.color2,
                        hintcolor: this.color3,
                    },
                    dialogue: {
                        establish: this.block1,  // 为1访客进入自动建立 为2访客点击图标建立
                        first: this.nummiao,
                        repeatedly: this.checked,  //反复弹开为true开启
                        repeatedlyTime: { interval: this.jiange, frequency: this.max },
                        coerce: this.checked1,
                    },
                    appearance: {
                        ratio: this.gaodu,
                        color: { navigation: this.dhlys, title: this.bttbys, service: this.kfqpys, news: this.kqxxys, caller: this.fkqpys, callerxx: this.fkxxys },
                        card: this.mingp,
                    },
                    menu: {
                        automatic: this.xiaox,
                        Text: this.tableData
                    },
                    station:{
                        TexT:this.table1Data
                    }

                }
                // console.log(res)
                let data = await addStyle({
                    site_id: this.optionsId,
                    type: 1,
                    setting: JSON.stringify(res)
                })
                if (data.data.code == 200) {
                    alert("保存更新成功")
                } else {
                    alert("保存失败")
                }
            } else {
                alert("请选择要修改的站点")
            }

        },
        // 还原更新
        async restore() {
            if (this.optionsId) {
                let res = await getStyle({
                    site_id: this.optionsId,
                    type: 1
                })
                // // console.log(res.data.data)
                if (res.data.data.length) {
                    // console.log(JSON.parse(res.data.data[0].setting))
                    let par = JSON.parse(res.data.data[0].setting)
                    this.num = par.icon.type
                    this.color1 = par.icon.colour
                    this.input = par.icon.text
                    this.num1 = par.icon.location.top
                    this.num2 = par.icon.location.left
                    this.block = par.icon.hintHide
                    this.color2 = par.icon.hintbgcolor
                    this.color3 = par.icon.hintcolor

                    this.block1 = par.dialogue.establish
                    this.nummiao = par.dialogue.first
                    this.checked = par.dialogue.repeatedly
                    this.jiange = par.dialogue.repeatedlyTime.interval
                    this.max = par.dialogue.repeatedlyTime.frequency
                    this.checked1 = par.dialogue.coerce

                    if (par.appearance.ratio == 215.8) {
                        this.radio1=3
                    } else if (par.appearance.ratio == 317.4) {
                        this.radio1=6
                    } else if (par.appearance.ratio == 368.2) {
                        this.radio1=9
                    } else {
                        this.radio1=12
                    }
                    this.gaodu=par.appearance.ratio
                    this.dhlys = par.appearance.color.navigation
                    this.bttbys = par.appearance.color.title
                    this.kfqpys = par.appearance.color.service
                    this.kqxxys = par.appearance.color.news
                    this.fkqpys = par.appearance.color.caller
                    this.fkxxys = par.appearance.color.callerxx
                    this.mingp = par.appearance.card

                    this.xiaox = par.menu.automatic
                    this.tableData = par.menu.Text
                    this.table1Data=par.station.TexT
                }
            }else{
                alert("你还未选择站点")
            }
        }
    },
};
</script>

<style lang="less" scoped>
.maxapp{
    height: calc(100vh - 80px);
}
.site {
    padding: 10px 0 0 10px;
}

.maxyd {
    width: 100%;
    padding: 10px 0 45px 10px;
    display: flex;
}

.maxyd .yd_left {
    margin-right: 20px;
    width: 480px;
    height: calc(100% - 90px);
}

.maxyd .yd_left .zixun {
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid #d8dfea;
    background-color: #fff;
    border-radius: 2px;
}

.maxyd .yd_left .zixun p {
    padding: 15px;
    margin: 0;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #28334b;
    border-bottom: 1px solid #d8dfea;
}

.maxyd .yd_left .zixun .tubiao {
    padding: 20px 15px 0;
}

.maxyd .yd_left .zixun .tubiao .tubiao_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}
.maxyd .yd_left .zixun .tubiao .tubiao_top div{
    cursor: pointer;
}
.active {
    color: rgb(59, 153, 252);
}
.background{
    background-color: rgb(59, 153, 252) !important;
}
.border{
    border: 3px solid rgb(59, 153, 252) !important;
}

.active1 {
    border-color: #1890ff;
    color: #1890ff;
}



.flex {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;
}

.flex2 {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.flex1 {
    display: flex;
    align-items: baseline;
    font-size: 12px;
}

.lf span:nth-child(1) {
    margin-right: 60px;
}

.lf1 span:nth-child(1) {
    margin-right: 60px;
}

.lf2 span:nth-child(1) {
    margin-right: 30px;
}

.flex1 span:nth-child(1) {
    margin-right: 30px;
}

.weizhi {
    width: 80px;
}

.weizhi ::v-deep .el-input input {
    padding: 0;
    width: 80px;
    text-align: left;
}

.wenan {
    width: 150px;
}

.tishi .el-button {
    padding: 7px 9px !important;
}

.ziyanse {
    color: #218bfc;
    cursor: pointer;
}

.ziyanse:hover {
    color: #4DA2FD;
}


// 右侧
.yd_right {
    position: relative;
    width: 320px;
    height: 553px;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #dfe4ec;
}

.yd_right .glay-one {
    position: absolute;
    top: 15px;
    left: 20px;
    width: 90px;
    height: 30px;
    background: #e6e9ef;
    border-radius: 2px;
}

.yd_right .glay-two {
    position: absolute;
    top: 55px;
    left: 20px;
    width: 280px;
    height: 140px;
    background: #e6e9ef;
    border-radius: 2px;
}

.yd_right .glay-three {
    position: absolute;
    top: 215px;
    left: 115px;
    width: 90px;
    height: 20px;
    background: #e6e9ef;
    border-radius: 12px;
}

.yd_right .glay-four {
    position: absolute;
    top: 245px;
    left: 45px;
    width: 230px;
    height: 10px;
    background: #e6e9ef;
    border-radius: 8px;
}

.yd_right .glay-five {
    position: absolute;
    top: 265px;
    left: 55px;
    width: 210px;
    height: 10px;
    background: #e6e9ef;
    border-radius: 8px;
}

.yd_right .glay-six {
    position: absolute;
    top: 285px;
    left: 20px;
    width: 280px;
    height: 208px;
    background: #e6e9ef;
    border-radius: 2px;
}

.footer {
    width: 100%;
    height: 44px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #dfe4ec;
}

.footer ul {
    width: 100%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer ul li {
    width: 20%;
    height: 100%;
    float: left;
    text-align: center;
    line-height: 44px;
    font-size: 24px;
    color: #eaedf1;
}

.preview-guide-card-atbottom-box {
    position: absolute;
    bottom: 109px;
    left: 1px;
    width: 280px;
    padding: 8px 28px 0 8px;
    background: #fff;
    box-shadow: 0 1px 4px 0 hsl(0deg 0% 77% / 50%), 0 -1px 4px 0 hsl(0deg 0% 90% / 50%);
    border-radius: 2px;
    border: 1px solid #dde5ed;
}

.preview-guide-card-atbottom-box .preview-guide-card-atbottom-text {
    height: 36px;
    font-size: 12px;
    font-weight: 400;
    color: #28334b;
    line-height: 18px;
    margin-bottom: 8px;
}

.preview-guide-card-atbottom-box .preview-guide-card-atbottom-close-icon {
    cursor: pointer;
    display: block;
    text-decoration: none;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 16px;
    height: 16px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAV1BMVEUAAABVVVU2QVE1Pk01P082Pk02P002P01GRl05Pk81P042Pk41Pk02PU42P02AgIA1Pk42P048PEs2P002Pk03QE01Pk1FTVqytbs4QU/h4uX////O0dQYvcVVAAAAHXRSTlMABi9WboSOlgs6aZOZS5ICPooReiE4lZ7Lmuj/3IEpewkAAAEXSURBVHgBlJJVgoQwEAW7cXm4J7n/Nde3x95Y/WBVQERu0ChO0izPszSJI5UnFGVV44y6KosHujYtbmgbFU7XD6AMfcf8ccJdpvHWnwc8YJiv/WXFQ9blysdTLop5xVPWWYxxwAsMNvJuwktM3V/Q261tP3DFsW923v/6OpjvfDiu/OCdFYP+BM2Z760w358VjXxR2P7ZvbfC/G9221fFV1BePbaC3ED5FVTgBfNRiWgNXjAftUoE8IL5QCQxeMF9xJKAF9xHIil4wX2kkoEX3EcmOXjBfeTvB5+V1gEBACAAwrD+rZdhDVThp4+0l+5n/Y/baHT4Pt5ZoK7oQ+Ax8yB7VD6MH/cvlFfWS/G1+2L/6TDjJOYP38ttOb4EbRsAAAAASUVORK5CYII=) no-repeat;
    background-position: 50%;
    background-size: 16px 16px;
    opacity: .6;
}


.preview-menu-box {
    width: 100%;
    position: absolute;
    bottom: 44px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    border-bottom: none;
}

.preview-menu-box .preview-menu-bottom-tb {

    cursor: pointer;
    float: left;
    width: 100%;
    color: #fff;
}

.preview-menu-box .preview-menu-bottom-tb .preview-menu-bottom-content-tb {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 49px;
}

.preview-menu-box .preview-menu-bottom-tb .preview-menu-bottom-content-tb .preview-menu-bottom-img-tb {
    position: relative;
    margin-top: 5px;
}

.preview-menu-bottom-info-num-tb {
    width: 16px;
    border-radius: 50%;
    background: red;
    color: #fff;
    position: absolute;
    right: -7px;
    line-height: 16px;
    text-align: center;
    top: -4px;
}

.preview-menu-bottom-img-tb img {
    width: 24px;
}

// <!-- 圆形 -->
.preview-box-content-radius-qipaobox {
    position: absolute;
}

.preview-box-content-radius-qipaobox .preview-box-radius-qipao {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 48px;
    background: #218bfc;
    box-shadow: 0 2px 32px 0 rgb(0 0 0 / 15%), 0 1px 6px 0 rgb(0 0 0 / 9%);
    border-radius: 50%;
}

.preview-box-content-radius-qipaobox .preview-box-radius-qipao img {
    margin: 8px;
}

.preview-box-content-radius-qipaobox .preview-box-radius-qipao .preview-box-radius-qipao-redtext {
    position: absolute;
    top: 4px;
    right: 4px;
    display: inline-block;
    width: 16px;
    height: 16px;
    font-size: 12px;
    color: #fff;
    line-height: 16px;
    background: red;
    text-align: center;
    border-radius: 50%;
}

.preview-box-lateral-picture-box {
    position: absolute;
    width: 32px;
    border-radius: 2px;
}

.preview-box-lateral-picture-box .preview-box-lateral-picture {
    width: 20px;
    height: 20px;
    margin: 4px 6px;
}

.preview-box-lateral-picture-box .preview-box-lateral-picture img {
    width: 20px;
    height: 20px;
}

.preview-box-lateral-picture .preview-box-lateral-number {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: red;
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
    line-height: 16px;
    text-align: center;
}

.preview-box-lateral-picture-box .preview-box-lateral-text {
    width: 16px;
    max-height: 164px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 7px 8px 8px;
    font-size: 14px;
    color: #fff;
    line-height: 18px;
    word-wrap: break-word;
}

.preview-box-content-inputbox {
    position: absolute;
    bottom: 42px;
    width: 318px;
    display: flex;
    flex-direction: column;
}

.preview-box-content-inputbox .preview-box-content-inputbox-body {
    height: 50px;
    box-shadow: 0 -1px 6px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    border-radius: 8px 8px 0 0;
}

.preview-box-content-inputbox-body img {
    width: 34px;
    height: 34px;
    margin: 8px 8px 8px 12px;
    float: left;
}

.preview-box-content-inputbox-body .preview-box-content-inputbox-bodymsg {
    float: left;
    width: 186px;
    height: 34px;
    background: #eff3f6;
    border-radius: 2px;
    font-size: 13px;
    color: #28334b;
    line-height: 18px;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 8px;
}

.preview-box-content-inputbox-body .preview-box-content-inputbox-msgtips {
    position: absolute;
    padding: 1px 6px;
    height: 20px;
    background: #f44024;
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
    line-height: 18px;
    right: 4px;
    top: 4px;
}

.preview-box-content-inputbox .preview-box-content-inputbox-foot {
    width: 318px;
    height: 45px;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.preview-box-content-inputbox-foot .foot-smile {
    float: left;
    margin: 8px 12px 2px 8px;
}

.preview-box-content-inputbox .preview-box-content-inputbox-foot .icon {
    font-size: 28px;
    color: #466080;
}

.preview-box-content-inputbox-foot .foot-add {
    float: left;
    margin: 8px 12px 2px 0;
}

.preview-box-content-inputbox-foot .foot-text {
    height: 20px;
    font-size: 14px;
    color: #8da2b5;
    line-height: 45px;
}

.preview-box-content-inputbox-foot .foot-text-next {
    float: right;
    margin: 11px 12px 12px 0;
    height: 20px;
    font-size: 14px;
    color: #8da2b5;
    line-height: 20px;
}

.preview-box-autoopen-content {
    height: 508px;
    background: #0f0100;
    opacity: .7;
}

.preview-box-customer-talk-box {
    position: absolute;
    bottom: 42px;
    width: 318px;
    display: flex;
    flex-direction: column;
}

.preview-box-customer-talk-box .preview-box-customer-talk-head {
    width: 100%;
    height: 44px;
    border-radius: 8px 8px 0 0;
}

.preview-box-customer-talk-head .head-text {
    margin-left: 12px;
    font-size: 16px;
    line-height: 44px;
}

.preview-box-customer-talk-head .head-close {
    float: right;
    margin: 8px 10px;
}

.preview-box-customer-talk-head .head-close .icon,
.preview-box-customer-talk-head .head-phone .icon {
    font-size: 26px;
}

.preview-box-customer-talk-head .head-phone {
    float: right;
    margin: 8px 10px 8px 0;
}

.preview-box-customer-talk-box .preview-box-customer-talk-body {
    position: relative;
    width: 318px;
    min-height: 215px;
    background: #fff;
    overflow: hidden;
}

.preview-box-customer-talk-body .preview-box-customer-talk-qipao {
    position: absolute;
    top: 16px;
    right: 12px;
    width: 71px;
    height: 20px;
    background: #218bfc;
    border-radius: 2px;
    padding: 8px;
    font-size: 13px;
    color: #fff;
    line-height: 18px;
}

.preview-box-customer-talk-body .preview-box-servicer-talk-qipao {
    position: absolute;
    display: flex;
    top: 66px;
    left: 12px;
}

.preview-box-customer-talk-body .preview-box-servicer-talk-qipao img {
    width: 34px;
    height: 34px;
    margin-right: 8px;
}

.preview-box-customer-talk-body .preview-box-servicer-talk-qipao span {
    width: 71px;
    height: 20px;
    background: #eff3f6;
    border-radius: 2px;
    font-size: 13px;
    color: #28334b;
    line-height: 18px;
    display: inline-block;
    padding: 8px;
}

.preview-box-customer-talk-body .mySwiper-box {
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0;
    background: #fff;
    z-index: 2;
    box-shadow: 0 -1px 0 0 #d8dfea;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-container-pointer-events {
    touch-action: pan-y;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

// .swiper-slide {
//     flex-shrink: 0;
//     width: 100%;
//     height: 100%;
//     position: relative;
//     transition-property: transform;
// }

// .mySwiper .hudong-menu-box {
//     width: 100%;
//     display: flex;
//     flex-wrap: nowrap;
//     flex-direction: row;
// }

.usermenuitem {
    height: 26px;
    padding: 4px 16px;
    margin: 7px 4px 7px 8px;
    background: #fff;
    border-radius: 20px;
    border: 1px solid #d8dfea;
    font-size: 12px;
    color: #62778c;
    line-height: 26px;
    cursor: pointer;
    white-space: nowrap;
}

.preview-box-customer-talk-body .mySwiper-box .close-box {
    height: 100%;
    width: 48px;
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 6px;
    z-index: 100;
    text-align: center;
    font-size: 0;
    cursor: pointer;
}

.preview-box-customer-talk-body .mySwiper-box .close-box .menu_btn {
    font-size: 12px;
    height: 14px;
    color: #62778c;
    padding: 7px 12px;
    border-radius: 15px;
    border: 1px solid #ced5e0;
    text-align: center;
    display: inline-block;
    width: auto;
    margin: 0 6px;
    cursor: pointer;
}

.preview-box-customer-talk-box .preview-box-customer-talk-foot {
    width: 318px;
    height: 45px;
    background: #fff;
    box-shadow: 0 -1px 0 0 #d8dfea;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.preview-box-customer-talk-foot .foot-smile {
    float: left;
    margin: 6px 12px 2px 8px;
}

.preview-box-customer-talk-foot .icon {
    font-size: 28px;
    color: #466080;
}

.preview-box-customer-talk-foot .foot-add {
    float: left;
    margin: 6px 12px 2px 0;
}

.preview-box-customer-talk-foot .foot-text {
    height: 20px;
    font-size: 14px;
    color: #8da2b5;
    line-height: 45px;
}

.preview-box-customer-talk-foot .foot-text-next {
    float: right;
    margin: 11px 12px 12px 0;
    height: 20px;
    font-size: 14px;
    color: #8da2b5;
    line-height: 20px;
}


// 保存更新
.baocun {
    width: 100%;
    height: 45px;
    background: #fff;
    border-top: 1px solid #ced5e0;
    padding: 0 15px;
    position: fixed;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
}



.duihua,
.waiguang,
.hudong,
.choujiang {
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid #d8dfea;
    background-color: #fff;
    border-radius: 2px;
}

.duihua p,
.waiguang .liaotian,
.hudong dl,
.choujiang p {
    padding: 15px;
    margin: 0;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #28334b;
    border-bottom: 1px solid #d8dfea;
}

.waiguang .liaotian p {
    margin: 0;
}

.duihua_boot {
    padding: 20px 15px 0;
}


.flex6 {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
    font-size: 12px;
}

.flex5 {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.duihua .flex span:nth-child(1) {
    margin-right: 30px;
}

.radio ::v-deep .el-radio {
    margin-right: 20px !important;
}

.radio ::v-deep .el-radio .el-radio__label {
    padding-left: 5px !important;
    font-size: 12px;
}

.weizhi {
    width: 80px;
}

.weizhi ::v-deep .el-input input {
    padding: 0;
    width: 80px;
    text-align: left;
}

.waiguang .liaotian span {
    color: #62778c;
    font-size: 12px;
}

.ckys {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 270px;
}

.ckys .xuanzhe {
    width: 130px;
    height: 45px;
    display: flex;
    align-items: center;
}

.qidong {
    display: flex;
    align-items: center;
}

.buzhic {
    margin-left: 30px;
}

.flex span:nth-child(1) {
    margin-right: 30px;
}

.xiaoguo {
    color: #c72892;
    font-size: 12px;
    margin-left: 20px;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #000 !important;
}

.hudong dl dd {
    margin: 10px 0 0 0;
    color: #62778c;
    font-size: 12px;
}

.hudong dl dd i {
    margin-right: 5px;
}

.xinjian {
    font-size: 12px;
    color: #218bfc;
    cursor: pointer;
}

.construction ::v-deep .el-form-item__content {
    display: flex;
}

.construction ::v-deep .el-form-item__content .el-input {
    width: 70% !important;
}

.construction .caidan {
    margin: 0 0 0 5px;
    padding: 0;
    cursor: pointer;
}

.demo-dynamic ::v-deep .el-form-item .el-form-item__content .el-input {
    width: 70% !important;
}
::v-deep .el-table__body .el-table__row .cell{
    display: flex;
    align-items: center;
}
.ico_tu{
    height: 45px;
    width: 45px;
    border: 3px solid gainsboro;
}
.ico_tu .chang{
    height: 10px;
    background-color: gainsboro;
    margin-top: 35px;
    border: 1px solid transparent;
}

.middle_zhong{
    text-align: center;
    margin-top: 5px;
}
.ico_tu .chang1{
    background-color: gainsboro;
    height: 12px;
    border-radius: 50%;
    width: 12px;
    margin-top: 30px;
    margin-left: 30px;
}
.ico_tu .chang2{
    height: 30px;
    width: 5px;
    background-color: gainsboro;
    margin: 8px 0 0 40px;
}
.ico_tu .chang div{
    height: 7px;
    width: 35px;
    background-color: #fff;
    margin: 2px 0 0 4px;
    border-radius: 5px;
}
</style>